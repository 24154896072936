import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  { // 配置默认路由
    path: "/", // 路由地址
    redirect: "/index" // 重定向
  },
  {
    path: '/index',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
  },
  {
    path: '/companyCulture',
    name: 'companyCulture',
    component: () => import(/* webpackChunkName: "about" */ '../views/companyCulture/index.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs/index.vue')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/joinUs/index.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})
router.afterEach((to, from, next) => {
  // window.scrollTo(5000,0)
});


export default router
